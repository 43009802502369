import jQuery from 'jquery/dist/jquery';
import * as bootstrap from 'bootstrap'
import Utils from '../../../../../utils'
import ErpProductEntity from "../../entity";
import UtilsEntity from "../../../../../utils/entity";
import {German} from "flatpickr/dist/l10n/de";

export default class ErpProductStock {
    private parent: any;
    private entity = "erp/product";
    private toastr: any;
    private datatables = [];
    private detailModal: bootstrap.Modal;

    constructor(parent: any) {
        this.parent = parent
        this.toastr = parent.toastr
        this.detailModal = new bootstrap.Modal((document.querySelector("#erpProductStockWarehouseLocationModal") as HTMLElement));
        this.bindListeners();
    }

    getEntityData(elem: any) {
        return {...ErpProductEntity.getEntityData(elem), id: this.parent.id}
    }

    async getSubentities() {
        const r = await UtilsEntity.getAll("product_attributes");
        if (r.status === 200) {
            return r.data.concat([{name: "order_number", editable: true}, {name: "external_order_number", editable: true}, {name: "tracking_company", editable: true}, {name: "tracking_number", editable: true}])
        } else {
            return {}
        }
    }

    bindListeners() {
        jQuery("#erpProductStockWarehouseLocationModalTable").delegate(".payload_changeable", "change", async (e) => {
            e.preventDefault();
            const target = e.currentTarget;
            const value = jQuery(target).val();
            const entity = target.getAttribute("data-entity");
            const mapId = target.closest("tr")?.getAttribute("data-id");
            const mapData = await UtilsEntity.get(mapId, 'erp/warehouse_location_product_mappings', null, null, null, true);
            if (mapData.status === 200) {
                const map = mapData.data;
                let payload = map.payload;
                if (!payload) {
                    payload = {}
                }
                payload[entity] = value
                await UtilsEntity.upsert({
                    id: map.uuid,
                    uuid: map.uuid,
                    payload: payload
                }, 'erp/warehouse_location_product_mappings');
            }
        });
        jQuery("#erp-product-supplier-tabs-content").delegate(".open_warehouse_location_details", "click", async (e) => {
            e.preventDefault();
            const title = e.currentTarget?.getAttribute("data-title")
            const serial = e.currentTarget?.getAttribute("data-serial")
            const customerId = e.currentTarget?.getAttribute("data-customer-id")
            const warehouseLocationId = e.currentTarget?.getAttribute("data-warehouse-location-id")
            const warehouseLocationPalletId = e.currentTarget?.getAttribute("data-warehouse-location-pallet-id")
            await Utils.showLoader();
            const r = await UtilsEntity.request(`/api/v1/erp/warehouse_location_product_mappings/by_warehouse_location_id`, 'POST', {
                customer_id: customerId || null,
                product_id: this.parent.id || null,
                warehouse_location_id: warehouseLocationId || null,
                warehouse_location_pallet_id: warehouseLocationPalletId || null,
                serial: serial
            });

            const subEntries = await this.getSubentities();
            console.log("subEntries", subEntries)
            let html = '<table class="table table-striped"><thead><tr>';
            if (r && r.status === 200) {
                const data = r.data;
                html += `<th>Serial</th>`
                subEntries.forEach((entity: any) => {
                    html += `<th>${entity.name.replaceAll("_", " ")}</th>`
                })
                html += `</tr></thead><tbody>`;
                data.forEach((d: any, i: number) => {
                    html += `<tr data-id="${d.id}">`
                    html += `<td>${d.serials && d.serials[0] !== null ? d.serials[0] : ''}</td>`
                    subEntries.forEach((key: any) => {
                        if (key.editable) {
                            if (key.input_type === "select") {
                                let optionsHtml = '';
                                const currentValue = d.payload && d.payload[key.name] ? d.payload[key.name] : ''
                                    key.options.forEach((opt: any) => {
                                    optionsHtml += `<option value="${opt}" ${currentValue.trim() === opt.trim() ? `selected="selected"` : ` `}>${opt.trim()}</option>`
                                })
                                html += `<td><select class="form-select payload_changeable" data-entity="${key.name}">${optionsHtml}</select></td>`
                            } else {
                                html += `<td><input type="${key.input_type}" class="form-control payload_changeable" value="${d.payload && d.payload[key.name] ? d.payload[key.name] : ''}" data-entity="${key.name}" /> </td>`
                            }
                        } else {
                            html += `<td>${d.payload && d.payload[key.name] ? d.payload[key.name] : ''}</td>`
                        }
                    })
                    html += `</tr>`
                })
                html += `</tbody></table>`
            }
            const modalTable = document.querySelector("#erpProductStockWarehouseLocationModalTable");
            const modalTitle = document.querySelector("#erpProductStockWarehouseLocationModalTitle");
            if (modalTable) {
                modalTable.innerHTML = html;
            }
            if (modalTitle) {
                modalTitle.innerHTML = title;
            }

            jQuery("#erpProductStockWarehouseLocationModalTable table").DataTable({
                dom:
                    '<"row me-2 align-items-center"' +
                    '<"col-md-2"<"me-3 m-3"l>>' +
                    '<"col-md-10"<"dt-action-buttons text-xl-end text-lg-start text-md-end text-start d-flex align-items-center justify-content-end flex-md-row flex-column mb-3 mb-md-0"fB>>' +
                    '>t' +
                    '<"row mx-2 align-items-center justify-content-between"' +
                    '<"col-6"i>' +
                    '<"col-6 mt-3"p>' +
                    '>',
                language: {
                    sLengthMenu: '_MENU_',
                    search: '',
                    searchPlaceholder: `${Utils.translate('generic.search')}...`,
                    "zeroRecords": `${Utils.translate('generic.datatable.no_results')}`,
                    "emptyTable": `${Utils.translate('generic.datatable.no_results')}`,
                    "paginate": {
                        "first": `${Utils.translate('generic.datatable.pagination.first')}`,
                        "last": `${Utils.translate('generic.datatable.pagination.last')}`,
                        "next": `${Utils.translate('generic.datatable.pagination.next')}`,
                        "previous": `${Utils.translate('generic.datatable.pagination.previous')}`
                    },
                    "info": `${Utils.translate('generic.datatable.info.info')}`,
                    "infoEmpty": `${Utils.translate('generic.datatable.info.empty')}`,
                    "infoFiltered": `${Utils.translate('generic.datatable.info.filtered')}`,
                },
                buttons: []
            });
            this.detailModal.show();
            await Utils.hideLoader();
        })
    }

    loadDatatables(data: any) {
        this.datatables.forEach((table: any) => {
            table.destroy();
        })
        const elems = document.querySelectorAll(".datatable-erp-product-stock") as NodeListOf<HTMLElement>;
        elems.forEach((elem, i) => {
            //@ts-ignore
            this.datatables.push(jQuery(elem).DataTable({
                initComplete: (r: any) => {
                    setTimeout(() => {
                        const warehouseId = elem.closest("*[data-warehouse-id]")?.getAttribute("data-warehouse-id");
                        const customers = data.stock_info.filter(s => s.warehouse.uuid === warehouseId).flatMap(s => s.customer.name)
                        if (customers.length > 0) {
                            const t = this.datatables[i];
                            t.columns().every((col: any) => {
                                if (col === 1) {
                                    const column = t.column(col);
                                    const header = column.header();
                                    let filterElem: any = null
                                    filterElem = document.createElement('select');
                                    filterElem.multiple = true;
                                    filterElem.classList.add("form-select")
                                    header.appendChild(filterElem);
                                    filterElem.addEventListener("click", (e: any) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                    })
                                    filterElem.style.marginTop = "5px"
                                    header.appendChild(filterElem);

                                    const options: any = {}
                                    options[col] = customers
                                    Object.keys(options).forEach((key: any) => {
                                        const v = options[key].filter((value: any, index: any, array: string | any[]) => array.indexOf(value) === index);
                                        v.forEach((value: any) => {
                                            filterElem.add(new Option(value, value));
                                        })
                                    })
                                    if (filterElem.nodeName === "SELECT") {
                                        jQuery(filterElem).select2().on('select2:select select2:unselect', (e: any) => {
                                            //@ts-ignore
                                            const values = Array.from(filterElem.querySelectorAll("option:checked")).map((e) => {
                                                return jQuery.fn.DataTable.util.escapeRegex(e.value)
                                            })
                                            column.search(values.join("|").length > 0 ? '^' + values.join("|") + '$' : '', true, false).draw();
                                        });
                                    }
                                }
                            });
                        }
                    }, 10);
                },
                processing: true,
                dom:
                    '<"row me-2 align-items-center"' +
                    '<"col-md-2"<"me-3 m-3"l>>' +
                    '<"col-md-10"<"dt-action-buttons text-xl-end text-lg-start text-md-end text-start d-flex align-items-center justify-content-end flex-md-row flex-column mb-3 mb-md-0"fB>>' +
                    '>t' +
                    '<"row mx-2 align-items-center justify-content-between"' +
                    '<"col-6"i>' +
                    '<"col-6 mt-3"p>' +
                    '>',
                language: {
                    sLengthMenu: '_MENU_',
                    search: '',
                    searchPlaceholder: `${Utils.translate('generic.search')}...`,
                    "zeroRecords": `${Utils.translate('generic.datatable.no_results')}`,
                    "emptyTable": `${Utils.translate('generic.datatable.no_results')}`,
                    "paginate": {
                        "first": `${Utils.translate('generic.datatable.pagination.first')}`,
                        "last": `${Utils.translate('generic.datatable.pagination.last')}`,
                        "next": `${Utils.translate('generic.datatable.pagination.next')}`,
                        "previous": `${Utils.translate('generic.datatable.pagination.previous')}`
                    },
                    "info": `${Utils.translate('generic.datatable.info.info')}`,
                    "infoEmpty": `${Utils.translate('generic.datatable.info.empty')}`,
                    "infoFiltered": `${Utils.translate('generic.datatable.info.filtered')}`,
                },
                buttons: []
            }));
        })

    }

    async update(data: any) {
        const warehouseData = await Utils.entity.getAll('erp/warehouses', null)
        const warehouses = warehouseData.data
        const parentHeader = document.querySelector("#erp-product-supplier-tabs-header") as HTMLElement;
        const parentContent = document.querySelector("#erp-product-supplier-tabs-content") as HTMLElement;

        parentHeader.innerHTML = "";
        parentContent.innerHTML = "";
        warehouses.forEach((warehouse: any, i: number) => {
            let tr = '';
            const mappedMap: any[] = []

            data.stock_info.forEach((stock: any) => {
                if (stock.warehouse.uuid === warehouse.id) {
                    const storedMap = stock.stored_at.reduce((acc: any, e: any) => acc.set(e, (acc.get(e) || 0) + 1), new Map());
                    let storedText = []
                    storedMap.forEach((value: any, key: any) => {
                        const d = new Date(key);
                        storedText.push(`${value}x ${d.toLocaleString("de-DE", {
                            year: 'numeric',
                            month: 'numeric',
                            day: 'numeric',
                            hour: '2-digit',
                            minute: '2-digit'
                        })}`)
                    });
                    const title = `${stock.warehouse_location.name}${stock.warehouse_location_pallet.name ? `, ${stock.warehouse_location_pallet.name}` : ''}`
                    const serialHtml: any = [];
                    if (stock.serials) {
                        const serials = [...new Set(stock.serials.flat())];
                        // @ts-ignore
                        serials.forEach((serial: string) => {
                            if (serial && serial !== "null") {
                                serialHtml.push(`<a href="#" class="open_warehouse_location_details" data-serial="${serial}" data-title="${title}" data-customer-id="${stock.customer.uuid || ''}" data-warehouse-location-id="${stock.warehouse_location.uuid}" data-warehouse-location-pallet-id="${stock.warehouse_location_pallet.uuid || ''}">${serial}</a>`)
                            }
                        })
                    }
                    tr += `<tr>
                        <td>
                        <button class="btn btn-xs btn-primary open_warehouse_location_details" data-title="${title}" data-customer-id="${stock.customer.uuid || ''}" data-warehouse-location-id="${stock.warehouse_location.uuid}" data-warehouse-location-pallet-id="${stock.warehouse_location_pallet.uuid || ''}">
                        ${title}
                        </button>
                        </td>
                        <td>${stock.customer && stock.customer.name ? stock.customer.name : ""}</td>
                        <td>${serialHtml.join(", ")}</td>
                        <td>${stock.stock}</td>
                        <td>${stock.stock_reserved}</td>
                        <td>${storedText.join("<br />")}</td>
                        <td>0</td>
                        </tr>`
                }
            });
            let headingHtml = `<li class="nav-item" role="presentation">
              <button type="button" class="nav-link ${i === 0 ? "active" : ""}" role="tab" data-bs-toggle="tab" data-bs-target="#erp-product-erp-stock-w${warehouse.id}" aria-controls="erp-product-erp-stock-w${warehouse.id}">
                ${warehouse.name}
              </button>
            </li>`;
            let contentHtml = `<div class="tab-pane fade ${i === 0 ? "show active" : ""}" id="erp-product-erp-stock-w${warehouse.id}" data-warehouse-id="${warehouse.id}" role="tabpanel">
              <table class="datatables datatable-erp-product-stock table">
                  <thead class="border-top">
                  <tr>
                    <th style="vertical-align: top;">${Utils.translate("erp.warehouse_location.name")}</th>
                    <th style="vertical-align: top;">${Utils.translate("erp.customer.name")}</th>
                    <th style="vertical-align: top;">${Utils.translate("erp.warehouse_location.attributes.serials")}</th>
                    <th style="vertical-align: top;">${Utils.translate("erp.export.attributes.stock")}</th>
                    <th style="vertical-align: top;">${Utils.translate("erp.export.attributes.stockReserved")}</th>
                    <th style="vertical-align: top;">${Utils.translate("erp.warehouse_location.attributes.stored_at")}</th>
                    <th style="vertical-align: top;">${Utils.translate("erp.priority.name")}</th>
                  </tr>
                  </thead>
                  <tbody>`;
            contentHtml += tr;

            contentHtml += ` </tbody></table></div>`;
            parentHeader.innerHTML = parentHeader.innerHTML + headingHtml;
            parentContent.innerHTML = parentContent.innerHTML + contentHtml;
        })
        this.loadDatatables(data);
        await Utils.updateElements(data, '', (document.querySelector("#erp_product_stock") as HTMLElement))
        await this.checkParams();
    }

    async checkParams() {
        const url = new URL(document.location.href);
        const serial = url.searchParams.get("serial")
        if (serial) {
            let tableMatch = null;
            let paneMatch = null;
            document.querySelector("#stock-tab")?.click();
            console.log("datatables", this.datatables)
            this.datatables.forEach((table: any) => {
                table.search(serial).draw();
                const rows = table.rows({search: 'applied'}).data()
                if (rows.length === 0) {
                    table.search('').draw();
                } else {
                    tableMatch = table
                    paneMatch = table.table().container().closest(".tab-pane")
                }
            })
            console.log("match", paneMatch, tableMatch)
            if (paneMatch) {
                const paneId = paneMatch.getAttribute("id");
                const warehouseId = paneMatch.getAttribute("data-warehouse-id");
                document.querySelector(`button[aria-controls='${paneId}']`)?.click();
                const detailButton = tableMatch.rows({search: 'applied'}).nodes()[0].querySelector(".open_warehouse_location_details");
                detailButton.setAttribute("data-serial", serial);
                detailButton.click();
                detailButton.removeAttribute("data-serial")
            }
        }
    }
}